.steps-content {
  margin-top: 3em;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  height: 80em;
}

.steps-action {
  display: flex;
  margin-top: 3em;
  justify-content: center;
}

.set-height {
  height: 100%;
}

.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.thank-you h1 {
  margin: 2em 0;
}